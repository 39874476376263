/* ==========================================================================

                        C O N T A C T   U S

========================================================================== */

.cc-contact-us {
    padding-top: rem-calc(6);

    .cc-top-section {
        padding: 0 0 rem-calc(25) 0;

        .cc-cu-title {
            display: block;
            font-weight: $primary-font-semibold;
            color: $black;
            font-size: rem-calc(25);
            text-align: center;
            text-transform: uppercase;
            padding: 0;
            margin: 0;
        }

        .cc-cu-subtitle {

            display: block;
            font-weight: $primary-font-regular;
            color: $black;
            font-size: rem-calc(20);
            line-height: rem-calc(28);
            text-align: center;
            font-style: italic;
            padding-top: rem-calc(15);
            padding-bottom: 0;
            margin: 0;

        }
    }

    .cc-middle-section {
        padding: rem-calc(30) 0;
        border-top: 1px solid $grey2;
        border-bottom: 1px solid $grey2;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }

        .cc-item {

            @include media-breakpoint-down(md) {
                padding-bottom: rem-calc(30);
            }

            .cc-item-title {
                display: block;
                font-weight: $primary-font-semibold;
                color: $black;
                font-size: rem-calc(14);
                text-align: left;
                text-transform: uppercase;
                padding: 0;
                margin: 0;

                @include media-breakpoint-down(xs) {
                    text-align: center;
                }
            }

            .cc-item-text {
                display: block;
                font-weight: $primary-font-regular;
                color: $black;
                font-size: rem-calc(14);
                text-align: left;
                padding-top: rem-calc(7);
                margin: 0;

                @include media-breakpoint-down(xs) {
                    text-align: center;
                }

                a {
                    text-decoration: underline;
                    color: $black;
                }
            }
        }
    }

    .cc-bottom-section {
        padding: 0 0 rem-calc(40) 0;

        .cc-cu-title {
            display: block;
            font-weight: $primary-font-semibold;
            color: $black;
            font-size: rem-calc(20);
            text-align: center;
            text-transform: uppercase;
            padding: 0;
            margin: rem-calc(20) 0;
        }
        
        .cc-cu-subtitle {
            display: block;
            font-weight: $primary-font-regular;
            color: $black;
            font-size: rem-calc(20);
            line-height: rem-calc(28);
            text-align: center;
            font-style: italic;
            padding-bottom: rem-calc(30);
            margin: 0;
        }

        .cc-content-form {
            max-width: rem-calc(700);
            margin: 0 auto;

            &.complaints {
                max-width: none;

                .cc-content-radio {
                    display: flex;
                    flex-direction: column;
                }
            }

            .cc-content-input {
                div.is-invalid + span.invalid-feedback {
                    display: block;
                }
            }

            .cc-phone-input-box {
                display: flex;
                align-items: center;
                background: $white;
                border: 1px solid $grey2;
                border-radius: rem-calc(2);
                height: auto;
                min-height: rem-calc(44);
                overflow: hidden;
                padding: rem-calc(13) rem-calc(15) rem-calc(4);
                position: relative;
                text-align: left;
                width: 100%;

                &:hover,
                &.cc-hover,
                &:focus,
                &:active {
                    border-color: $grey1 !important;
                    box-shadow: none !important;
                    outline: none !important;
                }

                &.cc-valid {
                    border-color: $black !important;
                }

                &:disabled,
                &:read-only {
                    border-color: $warm-grey-05 !important;
                    color: $grey1;
                }

                &.cc-invalid,
                &.is-invalid {
                    border-color: $red1 !important;
                }

                .cc-phone-prefix {
                    color: $black;
                    display: block;
                    font-family: $primary-font;
                    font-size: rem-calc(16);
                    font-weight: $primary-font-regular;
                    height: auto;
                    padding: 0;
                }

                .cc-input {
                    flex-grow: 1;
                    background: $white;
                    border: none;
                    outline: none;
                    padding: 0;
                    margin-bottom: rem-calc(0);
                    min-height: 0;
                }
            }
        }

    }

    .cc-form-group {
        margin-bottom: rem-calc(16);

        .cc-select {
            margin-bottom: 0;
        }

        .cc-radio-label {
            margin: rem-calc(5) rem-calc(10) 0 0;
        }
    }
}
