@import "_settings/globalSetting";
@import "./product/originalAndLowestPrice";

.cc-page-designer,
.cc-recommended-slider {
    padding-top: rem-calc(23);
    padding-bottom: rem-calc(23);

    @include media-breakpoint-up(lg) {
        padding-top: rem-calc(45);
        padding-bottom: rem-calc(60);
    }

    [data-action="Product-Show"] & {
        @include media-breakpoint-up(lg) {
            padding-top: rem-calc(5);
            padding-bottom: rem-calc(25);
        }
    }

    &__carousel-wrp {
        margin: 0 rem-calc(-16);

        @include media-breakpoint-up(lg) {
            margin: 0 rem-calc(-24);
        }

        .cc-recommended-grid & {
            overflow: hidden;
        }
    }

    &__carousel {
        &:not(.slick-initialized) {
            .cc-recommended-slider__item {
                display: inline-block;
                vertical-align: top;
                width: 44%;

                @include media-breakpoint-up(lg) {
                    width: 15.38%;
                }
            }
        }

        .slick-track {
            margin: 0;
        }
    }

    &__title {
        display: block;
        font-family: $primary-font;
        font-weight: $primary-font-semibold;
        font-size: rem-calc(22);
        line-height: rem-calc(26);
        text-align: left;
        color: $black;
        margin: 0 0 rem-calc(30) 0;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(24);
            line-height: rem-calc(28);
            margin: 0 0 rem-calc(30) 0;
        }

        &-link {
            svg {
                display: inline-block;
                margin-left: rem-calc(16);
            }
        }
    }

    .cc-slider {
        .cc-icon {
            &:before {
                $svgColor: str-replace('' + $black, '#', '%23');
                background-color: transparent;
                background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 20 21'%3E%3Cpolygon class='cls-1' points='19.2 10 10.2 1 9.7 .5 8.7 1.6 9.2 2.1 16.8 9.8 1 9.8 .3 9.8 .3 11.3 1 11.3 16.8 11.3 9.2 18.9 8.7 19.4 9.7 20.5 10.2 20 19.2 11 19.7 10.5 19.2 10' fill='#{$svgColor}'/%3E%3C/svg%3E");
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100%;
                width: rem-calc(24);
                height: rem-calc(24);
                content: '';
            }
        }

        .slick-arrow:not(.slick-disabled) {
            .cc-icon {
                &:hover {
                    &:before {
                        $svgColor: str-replace('' + $red, '#', '%23');
                        background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 20 21'%3E%3Cpolygon class='cls-1' points='19.2 10 10.2 1 9.7 .5 8.7 1.6 9.2 2.1 16.8 9.8 1 9.8 .3 9.8 .3 11.3 1 11.3 16.8 11.3 9.2 18.9 8.7 19.4 9.7 20.5 10.2 20 19.2 11 19.7 10.5 19.2 10' fill='#{$svgColor}'/%3E%3C/svg%3E");
                    }
                }
            }
        }

        .slick-slide {
            padding-right: rem-calc(1);
        }

        .slick-arrow {
            position: absolute;
            top: rem-calc(-62);
            left: auto;
            z-index: 1;

            &.slick-disabled {
                opacity: 0.3;
                cursor: default;
            }

            &.cc-slider-prev {
                right: rem-calc(64);
            }

            &.cc-slider-next {
                right: rem-calc(24);
            }
        }


        .cc-icon.icon-arrow-left:before {
            transform: rotate(180deg)
        }
    }

    .cc-recommended-static__item {
        .cc-tile-open-overlay {
            display: none;
        }
    }

    .cc-product-tile {
        position: relative;

        @include media-breakpoint-up(lg) {
            &:hover {
                .cc-tile-open-overlay {
                    opacity: 1;
                }

                .cc-tile-image {
                    .image-2 {
                        img {
                            opacity: 1;
                        }
                    }
                }

                .cc-tile-colors {
                    .cc-color-label {
                        display: none;
                    }
                    .cc-color-listing {
                        display: block;
                    }
                }
            }
        }
    }

    .cc-tile-image {
        position: relative;
        font-size: 0;

        .image-2 {
            display: none;
        }

        img {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            .image-2 {
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;

                img {
                    @include animationSlow();
                    opacity: 0;
                }
            }
        }
    }

    .cc-product-label {
        top: rem-calc(8);
        left: rem-calc(8);
        bottom: auto;
        right: auto;
        font-size: rem-calc(10);
        line-height: rem-calc(14);
        text-transform: uppercase;
        color: $black;
        font-weight: $primary-font-extra-bold;
        width: calc(100% - #{rem-calc(38)});
        font-style: normal;

        @include media-breakpoint-up(lg) {
            top: rem-calc(12);
            left: rem-calc(16);
            font-size: rem-calc(12);
            width: calc(100% - #{rem-calc(68)});
        }

        &__brand {
            display: none;
        }

        .cc-container-dis-picture {
            img {
                max-height: rem-calc(50);
                width: auto;
            }
        }
    }

    .tile-body {
        padding: rem-calc(8) rem-calc(12);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(16);
        }

        .cc-tile-product-name {
            display: block;
            font-weight: $primary-font-medium;
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            color: $black;
            letter-spacing: 0.0013em;
            white-space: nowrap;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 0 rem-calc(2);

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                letter-spacing: 0.0015em;
                margin: 0 0 rem-calc(8);
            }
        }

        .cc-price--wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            gap: rem-calc(4);
            margin-top: rem-calc(2);

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                margin-top: rem-calc(8);
            }

            .cc-price--discount {
                display: none;
            }

            .cc-sales-reduced {
                .cc-price {
                    color: $red;
                    font-weight: 500;
                }
            }

            .cc-price-reduced {
                display: none;
                font-weight: 400;
                text-decoration: line-through;

                &:after {
                    display: none;
                }
            }
        }

        .cc-price {
            display: block;
            font-weight: $primary-font-medium;
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            color: $black;
            letter-spacing: 0.0013em;
            text-align: left;
            margin: 0;

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                letter-spacing: 0.0015em;
            }

            &--discount {
                display: none;
            }
        }
    }

    @include original-and-pp-price-container(12, 9, 18, 6, center);

    .cc-original-price-container,
    .cc-pp-price-container {
        justify-content: flex-start;
        font-size: rem-calc(9);
        line-height: rem-calc(12);
        letter-spacing: 0.02em;
        color: $warm-grey-05;
        margin-right: 0;
        margin-top: rem-calc(4);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(11);
            line-height: rem-calc(16);
            margin-right: 0;
            margin-top: rem-calc(2);
        }

        .cc-original-price-percentage {
            color: $warm-grey-05;
            font-weight: 400;
        }
    }

    .cc-tile-colors {
        margin: rem-calc(8) 0 0;

        .cc-color-label {
            display: block;
            font-size: rem-calc(11);
            line-height: rem-calc(16);
            letter-spacing: 0.02em;
            margin: 0;

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                letter-spacing: 0.01em;
            }
        }

        .cc-color-listing {
            display: none;
            min-height: rem-calc(16);

            @include media-breakpoint-up(lg) {
                min-height: rem-calc(18);
            }

            .cc-color {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .cc-color-link-content-activate {
                    width: rem-calc(16);
                    height: rem-calc(16);
                    margin: 0 rem-calc(6) 0 0;
                    border: rem-calc(1) solid transparent;
                    border-radius: rem-calc(2);
                    padding: 0;
                    overflow: hidden;
                    position: relative;

                    .cc-color-link-content {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: rem-calc(16);
                        height: rem-calc(16);
                        border-radius: rem-calc(2);
                        overflow: hidden;
                        border: none;
                    }

                    &:hover {
                        border-color: $grey3;
                        padding: rem-calc(2);

                        .cc-color-link-content {
                            width: rem-calc(12);
                            height: rem-calc(12);
                        }
                    }

                    &.active {
                        border-color: $black;
                        padding: rem-calc(2);

                        .cc-color-link-content {
                            width: rem-calc(12);
                            height: rem-calc(12);
                        }
                    }
                }
            }
        }
    }

    .cc-tile-sizes {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        backdrop-filter: blur(#{rem-calc(8)});
        background: rgba($black, 0.2);
        display: none;
        z-index: 5;

        @include media-breakpoint-up(lg) {
            background: transparent;
            position: absolute;
            height: auto;
            bottom: 100%;
            left: rem-calc(8);
            width: calc(100% - #{rem-calc(16)});
            z-index: 2;
        }

        &.cc-active {
            display: block;
        }

        &__wrp {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            border-radius: rem-calc(8) rem-calc(8) 0 0;
            background: $white;
            padding: rem-calc(16);

            @include media-breakpoint-up(lg) {
                position: absolute;
                padding: rem-calc(12);
                border-radius: rem-calc(2);
                box-shadow: 0 0 rem-calc(6) 0 rgba($black, 0.1);
            }

            .close {
                font-size: rem-calc(20);

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        &__prod-name {
            display: block;
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            font-weight: $primary-font-medium;
            margin: 0 0 rem-calc(8);
            padding-right: rem-calc(60);

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        &__prod-price {
            display: block;
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            font-weight: $primary-font-medium;
            margin: 0 0 rem-calc(16);

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .cc-size-label {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: 0.1em;
            color: $warm-grey-05;
            margin: rem-calc(16) 0 rem-calc(13);
            font-weight: $primary-font-medium;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                color: $black;
                margin: 0 0 rem-calc(12);
            }

            &__number {
                color: $black;
            }

            span {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        .cc-size-list {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-rows: auto;
            gap: rem-calc(4);

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            a, span {
                display: flex;
                width: 100%;
                border: rem-calc(1) solid $warm-grey-02;
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                padding: rem-calc(9);
                border-radius: rem-calc(2);
                color: $black;
                text-align: center;
                letter-spacing: 0.01em;
                font-weight: $primary-font-regular;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;

                &.disable {
                    border-color: $grey2;
                    cursor: not-allowed;
                    position: relative;
                    color: grey;
                }

                &.active {
                    border-color: $black;
                }

                &.cc-unselectable-size {
                    color: $warm-grey-04;
                }

                &.low-stock {
                    position: relative;
                    &::after {
                        background-color: $red;
                        border-radius: rem-calc(2);
                        bottom: rem-calc(9);
                        content: '';
                        height: rem-calc(4);
                        position: relative;
                        width: rem-calc(4);
                        display: inline-block;
                    }
                }

                .cc-backInStock-icon {
                    height: rem-calc(20);
                    width: rem-calc(16);
                    margin-left: rem-calc(4);

                    &-menu {
                        stroke: currentColor;

                        &:hover {
                            stroke: $red;
                        }
                    }


                    &.cc-icon-search {
                        display: none;
                    }

                    &:hover {
                        color: $red !important;
                        fill: $red !important;
                    }
                }
            }

            a:hover, span:hover {
                @include media-breakpoint-up(lg) {
                    color: $red;
                }

                &.active {
                    border-color: $red;
                }
            }
        }
    }

    .cc-tile-add-to-wishlist {
        display: none;
    }

    &.cc-recommended-grid {
        .product-tile {
            position: relative;
        }

        .cc-tile-add-to-wishlist {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            padding: rem-calc(8);
            width: rem-calc(30);
            height: rem-calc(30);
            z-index: 2;

            @include media-breakpoint-up(lg) {
                width: rem-calc(52);
                height: rem-calc(44);
                padding: rem-calc(13) rem-calc(17);
            }

            svg {
                display: block;
                width: rem-calc(14);
                height: rem-calc(14);

                @include media-breakpoint-up(lg) {
                    width: rem-calc(18);
                    height: rem-calc(18);
                }
            }

            &:hover {
                color: $red;
            }
        }

        .cc-tile-open-overlay {
            @include animation();
            position: absolute;
            top: rem-calc(8);
            right: rem-calc(8);
            background: $white;
            width: rem-calc(32);
            height: rem-calc(32);
            box-shadow: 0 0 rem-calc(6) 0 rgba(0,0,0,0.1);
            border-radius: rem-calc(2);
            z-index: 2;

            @include media-breakpoint-up(lg) {
                width: rem-calc(40);
                height: rem-calc(40);
                opacity: 0;
                top: rem-calc(-48);
            }

            &:hover {
                .cc-tile-open-overlay__plus {
                    &:before,
                    &:after {
                        background: $red;
                    }
                }
            }

            &__plus {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: rem-calc(12);
                    height: rem-calc(2);
                    background: $black;
                    margin-left: rem-calc(-6);
                    margin-top: rem-calc(-1);
                }

                &:after {
                    transform: rotate(90deg);
                }
            }
        }

        .tile-body {
            padding: rem-calc(8) rem-calc(48) rem-calc(56) rem-calc(12);
            margin: 0;
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            letter-spacing: 0.01em;
            text-align: left;
            background: linear-gradient(180deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
            position: relative;

            @include media-breakpoint-up(lg) {
                padding: rem-calc(16) rem-calc(16) rem-calc(64);
                font-size: rem-calc(15);
                line-height: rem-calc(22);
            }
        }

        .cc-recommended-grid__item {
            .cc-price--discount {
                display: none;
                color: $red;
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    .cc-tile-open-overlay {
                        opacity: 1;
                    }

                    .cc-tile-image {
                        .image-2 {
                            img {
                                opacity: 1;
                            }
                        }
                    }

                    .cc-tile-colors {
                        .cc-color-label {
                            display: none;
                        }
                        .cc-color-listing {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.cc-recommended-slider:not(.cc-recommended-grid) {
    .cc-tile-product-brand {
        display: none;
        .cc-menu__productsSection & {
            display: block;
        }
    }
}

.cc-plp__availabilty {
    color: $red;
    font-size: rem-calc(13);
    font-weight: $primary-font-medium;
    letter-spacing: rem-calc(0.13);
    line-height: rem-calc(18);
}

.plp-promotions {
    margin-top: rem-calc(2);

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(8);
    }

    .collapsible-xl {
        display: none;
    }

    .cc-plp-promo {
        font-size: rem-calc(9);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.9);
        font-weight: 700;
        color: $ruby-red;
        border-radius: rem-calc(2);
        background: rgba(230, 0, 0, 0.20);
        padding: rem-calc(2) rem-calc(6);
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: rem-calc(127);
        width: fit-content;

        @include media-breakpoint-up (lg) {
            font-size: rem-calc(12);
            letter-spacing: rem-calc(1.2);
            max-width: rem-calc(240);
        }
    }
}